@use "src/assets/shared/theme/variables/colors" as colors;
@use "src/assets/shared/mixins/texts" as texts;

body {
  width: 100vw;
  height: 100vh;
}

.new-big-title {
  @include texts.text-7-bolder;
  color: #000000;
}

.new-big-description {
  @include texts.text-5-regular;
  color: #6D6B6B;
}

.new-title {
  @include texts.text-4-regular;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
}

.new-description {
  @include texts.text-4-regular;
  color: #6D6B6B;
  white-space: pre-line;
}

.new-small-title {
  @include texts.text-4-bold;
  color: #000000;
}

.new-small-description {
  @include texts.text-3-regular;
  line-height: 20px;
  color: #6D6B6B;
}

.blue-cta {
  @include texts.text-4-bold;
  color: colors.$blue-payee;
  cursor: pointer;
}

.dialog-container {
  box-sizing: border-box;
  overflow-y: auto;
  width: 700px;
  padding: 30px 50px;
  background: #FBFBFB;
  border-radius: 5px;
  max-height: 90vh;
}

.small-dialog-container {
  box-sizing: border-box;
  overflow: hidden;
  width: 500px;
  padding: 40px 50px 45px 50px;
  background: #FFFFFF;
  box-shadow: 0px 8px 36px rgba(0, 0, 0, 0.16);
  border-radius: 3px;
}

.top-margin-5 {
  margin-top: 5px;
}

.top-margin-10 {
  margin-top: 10px;
}

.top-margin-15 {
  margin-top: 15px;
}

.top-margin-20 {
  margin-top: 20px;
}

.top-margin-25 {
  margin-top: 25px;
}

.top-margin-30 {
  margin-top: 30px;
}

.top-margin-40 {
  margin-top: 40px;
}

.top-margin-50 {
  margin-top: 50px;
}

.top-margin-60 {
  margin-top: 60px;
}

.top-margin-100 {
  margin-top: 100px;
}

.bottom-margin-5 {
  margin-bottom: 5px;
}

.bottom-margin-10 {
  margin-bottom: 10px;
}

.bottom-margin-15 {
  margin-bottom: 15px;
}

.bottom-margin-20 {
  margin-bottom: 20px;
}

.bottom-margin-25 {
  margin-bottom: 25px;
}

.bottom-margin-30 {
  margin-bottom: 30px;
}

.bottom-margin-40 {
  margin-bottom: 40px;
}

.bottom-margin-50 {
  margin-bottom: 50px;
}

.bottom-margin-60 {
  margin-bottom: 60px;
}

.bottom-margin-100 {
  margin-bottom: 100px;
}

.arrow-button {
  payee-icon {
    padding-left: 5px;
  }
}

.close {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.pointer {
  cursor: pointer;
}

.form-data-steps-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .middle-part {
    width: 600px;

    .small-title {
      @include texts.text-3-regular;
      line-height: 20px;
      text-transform: uppercase;
      color: #B4B4B4;
    }

    .buttons-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .backward-button {
        height: 48px;
        width: 120px;
      }

      .forward-button {
        height: 48px;
        width: 268px;
      }
    }
  }
}

.divider {
  width: 100%;
  height: 1px;
  border-bottom: solid 1px #B4B4B4;
}

.disabled {
  pointer-events: none!important;
  background: var(--grey-light, rgba(238, 238, 238, 0.93));
  color: var(--grey-medium, #B4B4B4);
}

.mat-tooltip {
  background-color: black;
  border-radius: 8px !important;
  max-width: 320px !important;
  @include texts.text-2-regular;
  line-height: 16px;
  text-align: center;
  color: #FFFFFF;
}
